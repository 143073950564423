import { combineReducers } from 'redux';
import cartReducer from '../app/features/cart/reducer';
import login from '../app/features/auth/login/reducer';
import register from '../app/features/auth/register/reducer';
import whishlist from '../app/features/wishlist/reducer'
import products from '../app/features/products/reducer'
import categories from '../app/features/category/reducer'

import feeds from '../app/features/feeds/reducer'

// Import other slice reducers as needed

const rootReducer = combineReducers({
  // cart: cartReducer,
  // Login: login,
  // Account: register,
  // Products: products,
  // Whislist: whishlist,
  // Categories: categories
  Feeds: feeds
  
 
  // Add other slices here
});

export default rootReducer;
