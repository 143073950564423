import {
GET_FEEDS_FAIL,
GET_FEEDS_REQUEST,
GET_FEEDS_SUCCESS,
ADD_FEEDS_FAIL,
ADD_FEEDS_REQUEST,
ADD_FEEDS_SUCCESS,
UPDATE_FEEDS,
UPDATE_FEEDS_FAIL,
UPDATE_FEEDS_SUCCESS
} from "./actionType";

// common success
export const getFeedsActionResponseSuccess = (data) => ({
  type: GET_FEEDS_SUCCESS,
  payload: data,
});
// common error
export const getFeedsActionResponseError = (error) => ({
  type: GET_FEEDS_FAIL,
  payload: error,
});

export const getFeedsAction = () => ({
  
  type: GET_FEEDS_REQUEST,
});



export const updateFeeds = customer => ({
  type: UPDATE_FEEDS,
  payload: customer,
});

export const updateFeedsSuccess = customer => ({
  type: UPDATE_FEEDS_SUCCESS,
  payload: customer,
});

export const updateFeedsFail = error => ({
  type: UPDATE_FEEDS_FAIL,
  payload: error,
});

export const addNewFeeds = payload => ({
  type: ADD_FEEDS_REQUEST,
  payload: payload,
});

export const addFeedsSuccess = customer => ({
  type: ADD_FEEDS_SUCCESS,
  payload: customer,
});

export const addFeedsFail = error => ({
  type: ADD_FEEDS_FAIL,
  payload: error,
});

// export const deleteFeeds = customer => ({
//   type: DELETE_CUSTOMER,
//   payload: customer,
// });

// export const deleteFeedsSuccess = customer => ({
//   type: DELETE_CUSTOMER_SUCCESS,
//   payload: customer,
// });

// export const deleteFeedsFail = error => ({
//   type: DELETE_CUSTOMER_FAIL,
//   payload: error,
// });