import { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Button,
  NavbarToggler,
  Input,
} from "reactstrap";
import img from "../assets/images/img.png";
import "./style.css";

import { Modal } from "reactstrap";

function Navs(args) {
  const [isOpen, setIsOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const screenHeight = window.innerHeight;
      const showNavbarCondition = scrollPosition > screenHeight * 0.7;

      setShowNavbar(showNavbarCondition);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div>
      <Navbar
        {...args}
        light
        expand="md"
        style={{
          display: showNavbar ? "block" : "none",
          position: "fixed",
          width: "100%",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white",
        }}
      >
        <div></div>
        <NavbarBrand href="/">
          <img src={img} className="img-fluid" width="100" alt="Logo"></img>
        </NavbarBrand>

        <NavbarToggler
          onClick={toggle}
          style={{ border: "none" }}
          className="menu-toggler"
        >
          <i className="bx bx-menu-alt-right"></i>
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="#">
              <NavbarText>Home</NavbarText>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#">
              <NavbarText>About</NavbarText>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#">
                <NavbarText>Investments</NavbarText>
              </NavLink>
            </NavItem>
          </Nav>
        
        </Collapse>

        <div>
          {/* <Input  type="text"
  placeholder="Your Placeholder"
  className="custom-input" /> */}
          <Button
            color="primary"
            onClick={toggleModal}
            style={{ backgroundColor: "white", color: "black", border: "none" }}
          >
            <i className="bx bx-search text-dark "></i>  <NavbarText>Search</NavbarText>
          </Button>
        </div>
      </Navbar>

      <div>
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          style={{ backgroundColor: "transparent" }}
          centered
        >
          <div
            className="text-center"
            style={{ backgroundColor: "transparent" }}
          >
            <Input
              type="text"
              placeholder="Search ..."
              className="custom-input p-3"
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Navs;
