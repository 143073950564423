import Carousel from "react-multi-carousel";
import img from "./bg-min.png";
import { useSelector } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import { Card, CardBody, CardImg, CardTitle, Button, Spinner } from "reactstrap";
import { useState } from "react";

const Sli = () => {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const { loading, feeds } = useSelector((state) => ({
    loading: state.Feeds.loading,
    feeds: state.Feeds.feeds,
  }));

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <>
      {loading === false ? (
        <>
          <Carousel responsive={responsive} className="">
            {feeds?.map((feed, index) => (
              <div className="container mt-5" key={index}>
                <Card style={{ border: "none" }}>
                  {!imageLoaded && (
                    <div
                      style={{
                        width: "100%",
                        height: "300px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      <Spinner color="dark" />
                    </div>
                  )}
                  <CardImg
                    top
                    height={"300"}
                    width={"300"}
                    src={
                      feed?.files[0]?.urlCdn ||
                      "https://wallpapers.com/images/hd/investment-background-uplvvqgyntu5n7gb.jpg"
                    }
                    alt="Card image cap"
                    onLoad={handleImageLoad}
                    style={{
                      display: imageLoaded ? "block" : "none",
                      position: !imageLoaded ? "absolute" : "static",
                    }}
                  />
                  <CardBody style={{ display: imageLoaded ? "block" : "none" }}>
                    <CardTitle tag="h5">{feed?.articlesName}</CardTitle>
                    <h6>{feed?.articlesShortDescription}</h6>
                    <p style={{ color: "#e3bd29" }}>Read more</p>
                  </CardBody>
                </Card>
              </div>
            ))}
          </Carousel>

          <div className="text-start mt-5">
            <Button
              style={{ borderRadius: "50px", backgroundColor: "" }}
              className="p-3 btn btn-dark"
            >
              Read More News
            </Button>
          </div>
        </>
      ) : (
        <div className="container mt-5 ">
          <Card style={{ border: "none" }}>
            <CardImg
              top
              width=""
              src={
                "https://miro.medium.com/v2/resize:fit:1200/1*YsfN1OBS0UIzAK2BAP_Z4A.gif"
              }
              alt="Card image cap"
              height="300"
              className="img-fluid"
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default Sli;
