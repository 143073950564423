import React, { useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { ToastContainer } from "react-toastify";
import Route from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { getFeedsAction } from "./store/rootAction";
import { AppContext } from "./helpers/AppContext";
import { Spinner } from "reactstrap"; // Use your preferred spinner component/library
import { RiseLoader, BarLoader } from "react-spinners";
import img from "./assets/images/img.png";
function App() {
  const dispatch = useDispatch();
  const { contentLoaded, markContentAsLoaded } = useContext(AppContext);

  useEffect(() => {
    // Dispatch your actions (e.g., fetching data) here
    dispatch(getFeedsAction());
  }, [dispatch]);

  // Simulate loading time (Replace with your actual content loading logic)
  useEffect(() => {
    const loadContent = async () => {
      // Simulate loading time (Replace with your actual content loading logic)
      await new Promise((resolve) => setTimeout(resolve, 5000));
      markContentAsLoaded();
    };

    loadContent();
  }, [markContentAsLoaded]);

  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {contentLoaded ? (
        // Render your page component when content is loaded
        <>
          <Navbar />
          <Route />
          <Footer />
        </>
      ) : (
        // Display a loading spinner while content is loading
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
        <div>
          {/* <Spinner size={'sm'}/> */}
        </div>
        <div className="d-grid align-items-center">
          <div>
            <img src={img} alt="img" className="img-fluid" width="300" />
          </div>
          <div className="text-center mt-4 d-flex justify-content-center">
            <BarLoader color={"#000000"} size={50} />
          </div>
        </div>
      </div>
      
      )}
    </Router>
  );
}

export default App;
