import React from "react";
import { Navigate } from "react-router-dom";


import Landing from '../Pages/Landing/LandingPage'

const publicRoutes = [

  { path: "/", component: <Landing /> },
 
  {
    path: "/",
    exact: true,
    component: <Navigate to="/" />,
  },
  { path: "*", component: <Navigate to="/" /> },
];

const authProtectedRoutes = [
  // Authentication Page

  // { path: "/home", component: <Home /> },
  
  // { path: "/shop", component: <Shop /> },
  // { path: "/shop/:id", component: <Product /> },
  // { path: "/cart", component: <Cart /> },
  // { path: "/orders", component: <Orders /> },
  // { path: "/profile", component: <Profile /> },
  // { path: "/wishlist", component: <WishList /> },
  // { path: "/checkout", component: <Checkout /> },
 
];




export { authProtectedRoutes, publicRoutes };
