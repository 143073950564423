import {
  GET_FEEDS_FAIL,
  GET_FEEDS_REQUEST,
  GET_FEEDS_SUCCESS,
  ADD_FEEDS_FAIL,
  ADD_FEEDS_REQUEST,
  ADD_FEEDS_SUCCESS,
  UPDATE_FEEDS,
  UPDATE_FEEDS_FAIL,
  UPDATE_FEEDS_SUCCESS,
  ADD_FEEDS_RESET,
} from "./actionType";

const INIT_STATE = {
  feeds: [],
  loadingfeeds: false,
  feedserror: null,
  saveloadding: false,
  saveerror: null,
  isFeedsCreated: false,
  updateloadding: false,
  updateerror: null,
  isFeedsUpdated: false,
};

const FeedsegoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEEDS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_FEEDS_SUCCESS:
      return {
        ...state,
        loading: false,
        feeds: action.payload,
        updateloadding: false,
        saveloadding: false,
      };
    case GET_FEEDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        feeds: [],
      };

    case ADD_FEEDS_SUCCESS:
      return {
        ...state,
        isFeedsCreated: true,
        saveloadding: false,
        updateloadding: false,
        loading: false,
        feeds: [...state.feeds, action.payload.data],
      };

    case ADD_FEEDS_FAIL:
      return {
        ...state,
        saveloadding: false,
        isFeedsCreated: false,
        error: action.payload,
        loading: false,
      };
    case ADD_FEEDS_REQUEST:
      return {
        ...state,
        saveloadding: true,
        loading: true,
      };
    case ADD_FEEDS_RESET:
      return {
        ...state,
        isFeedsCreated: false,
      };
    case UPDATE_FEEDS:
      return {
        ...state,
        updateloadding: true,
        saveloadding: false,
        loading: true,
      };
    case UPDATE_FEEDS_SUCCESS:
      // console.log(state.applist.map(app =>
      //   app.id === action.payload.id
      //     ? { ...app, ...action.payload }
      //     : app
      // ));
      return {
        ...state,
        feeds: state.feeds.map((item) =>
          item.category_id === action.payload.category_id
            ? { ...item, ...action.payload }
            : item
        ),
        isFeedsUpdated: true,
        updateloadding: false,
        loading: false,
      };
    case UPDATE_FEEDS_FAIL:
      return {
        ...state,
        updateerror: action.payload,
        isFeedsUpdated: false,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default FeedsegoryReducer;
