// Actions
//Get FEEDS
export const GET_FEEDS_REQUEST = "GET_FEEDS_REQUEST";
export const GET_FEEDS_SUCCESS = "GET_FEEDS_SUCCESS";
export const GET_FEEDS_FAIL = "GET_FEEDS_FAIL";

//Add FEEDS
export const ADD_FEEDS_REQUEST = "ADD_FEEDS_REQUEST";
export const ADD_FEEDS_SUCCESS = "ADD_FEEDS_SUCCESS";
export const ADD_FEEDS_FAIL = "ADD_FEEDS_FAIL";
export const ADD_FEEDS_RESET = "ADD_FEEDS_RESET";

/**f
 * Edit FEEDS
 */
export const UPDATE_FEEDS = "UPDATE_FEEDS";
export const UPDATE_FEEDS_SUCCESS = "UPDATE_FEEDS_SUCCESS";
export const UPDATE_FEEDS_FAIL = "UPDATE_FEEDS_FAIL";
export const UPDATE_FEEDS_RESET = " UPDATE_FEEDS_RESET";

/**
 * Delete FEEDS
 */
export const DELETE_FEEDS = "DELETE_FEEDS";
export const DELETE_FEEDS_SUCCESS = "DELETE_FEEDS_SUCCESS";
export const DELETE_FEEDS_FAIL = "DELETE_FEEDS_FAIL";
