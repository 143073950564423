import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [contentLoaded, setContentLoaded] = useState(false);

  const markContentAsLoaded = () => {
    setContentLoaded(true);
  };

  return (
    <AppContext.Provider value={{ contentLoaded, markContentAsLoaded }}>
      {children}
    </AppContext.Provider>
  );
};
