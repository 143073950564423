import { all } from "redux-saga/effects";
// import cartSaga from '../app/features/cart/cartSlice';
// Import other slice sagas as needed
import authSaga from "../app/features/auth/login/saga";
import AccountSaga from "../app/features/auth/register/saga";
import ProductsSaga from "../app/features/products/saga";
import CategoriesSaga from "../app/features/category/saga";

import feedsSaga from "../app/features/feeds/saga";

export default function* rootSaga() {
  yield all([
    // authSaga(),
    // AccountSaga(),
    // ProductsSaga(),
    // CategoriesSaga()
    feedsSaga(),
    // Add other sagas here
  ]);
}
