import { Container, Row, Col, Button } from "reactstrap";
import img from "../assets/images/img.png";

const Footer = () => {
  const circleButtonStyle = {
    borderRadius: "50%",
    width: "50px", // Adjust the width and height as needed
    height: "50px",
  };

  return (
    <footer className="footer p-3 p-md-5 mt-5" style={{ backgroundColor: "#e0e0e0" }}>
      <Container>
        <Row className="mb-4">
          <Col md={6} lg={4}>
            <div className="d-flex g-2" style={{flexWrap: 'wrap'}}>
              <div>
                <img src={img} className="img-fluid" width="100" alt="Logo"></img>
              </div>

              <div className="mt-2">
                <h5 className="fw-bolder">Who We Are</h5>
                <p>Email: info@example.com</p>
                <p>Phone: (123) 456-7890</p>
              </div>
            </div>
          </Col>
          <Col md={3} lg={3}>
            <h5 className="fw-bolder">Explore</h5>
            <p>
              <a href="/about" style={{ textDecoration: "none", color: "black" }}>
                About Us
              </a>
            </p>
            <p>
              <a href="/services" style={{ textDecoration: "none", color: "black" }}>
                Approach
              </a>
            </p>
            <p>
              <a href="/portfolio" style={{ textDecoration: "none", color: "black" }}>
                Portfolio
              </a>
            </p>
          </Col>
          <Col md={3} lg={3}>
            <h5 className="fw-bolder">Financial Services</h5>
            <p>
              <a href="/privacy-policy" style={{ textDecoration: "none", color: "black" }}>
                Privacy Policy
              </a>
            </p>
            <p>
              <a href="/terms-of-service" style={{ textDecoration: "none", color: "black" }}>
                Terms of Service
              </a>
            </p>
          </Col>

          {/* Socials */}
          <Col md={12} lg={2} className="mt-3 mt-lg-0 d-flex justify-content-md-end align-items-center">
  <Button style={circleButtonStyle} className="mr-2 btn-dark">
    <i className="bx bxl-linkedin"></i>
  </Button>
  <Button color="secondary" style={circleButtonStyle} className="mx-1 btn btn-dark">
    <i className="bx bxl-instagram"></i>
  </Button>
</Col>

        </Row>

        <hr className="mt-4 mb-3" />

        <Row>
          <Col md={12} className="text-center">
            <p>© {new Date().getFullYear()} Desiderata Investment LTD. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
