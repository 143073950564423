import axios from "axios";
import { api } from "../config";
import { useDispatch } from "react-redux";
import { getMe } from "../store/rootAction";

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.defaults.headers.common["X-RapidAPI-Key"] =
  "c2f75b5823msh8a1c571dfe26618p13ef55jsn6793b13f4acd";
axios.defaults.headers.common["X-RapidAPI-Host"] =
  "reuters-business-and-financial-news.p.rapidapi.com";

class APIClient {
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

export { APIClient };
