import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Ecoomerce Redux States
import { GET_FEEDS_REQUEST } from "./actionType";

import {
  getFeedsActionResponseError,
  getFeedsActionResponseSuccess,
} from "./action";
import { feedsAPICALL } from "../../../helpers/fakebackend_helper";

function* getFeeds() {
  try {
    const cat = "238";
    const date = "2024-01-12";
    const response = yield call(feedsAPICALL);
    if (response) {
      yield put(getFeedsActionResponseSuccess(response.data));
     
    } else {
      yield put(getFeedsActionResponseError(response));
   
    }
  } catch (error) {
    yield put(getFeedsActionResponseError(error, error));
  }
}

export function* watchGetFeeds() {
  yield takeEvery(GET_FEEDS_REQUEST, getFeeds);
}

function* feedsSaga() {
  yield all([fork(watchGetFeeds)]);
}

export default feedsSaga;
