import { Button, Col, Container, Row } from "reactstrap";

import "./landing.css";
import Sli from "./Slider";
import NewsSlider from "./NewsSlider";
import vid from "../../assets/video/video.mp4";
import { useState } from "react";
import { Spinner } from "reactstrap";
import Part2 from "./Part2";
import { BarLoader } from "react-spinners";

function LandingPage() {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <>
      {/* Video Part */}
      <div
        style={{
          backgroundColor: "white",
          height: "100svh",
          width: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {!videoLoaded && (
          // Skeleton loader while video is loading
          <div
            style={{
              backgroundColor: "white",

              animation: "loading-animation 1.5s infinite",
              marginTop: "20rem",
            }}
            className="d-flex justify-content-center align-items-center"
          >
           <div className="text-center mt-4 d-flex justify-content-center">
            <BarLoader color={"#000000"} size={50} />
          </div>
          </div>
        )}
        <video
          src={vid}
          autoPlay
          loop
          muted
          onLoadedData={handleVideoLoad}
          style={{
            width: "100%",
            opacity: videoLoaded ? 1 : 0, // Show the video when loaded
            transition: "opacity 1s ease-in-out", // Smooth transition for opacity
          }}
        ></video>
      </div>

      {/* Second Part */}
      <div style={{ display: !videoLoaded ? "none" : "" }}>
        <Part2 />

        {/* Part 3 */}
        <div
          style={{
            backgroundColor: "white",
            minHeight: "50vh",
            marginTop: "rem",
          }}
        >
          <Container>
            <h2 className="text-center  fw-lighter mb-5">Why Choose Us</h2>
            <div
              className="d-flex flex-column flex-xl-row gap-1"
              data-aos="fade-right"
            >
              <div
                style={{
                  backgroundColor: "#e3bd29",
                  minHeight: "40vh",

                  borderRadius: "10px",
                }}
                className="col-xl-5"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  Image
                </h3>
              </div>

              <Row>
                <div
                  className="d-flex flex-column flex-md-row gap-4 mx-4"
                  style={{
                    justifyContent: "space-evenly",
                    marginTop: "7rem",
                    flexWrap: "",
                  }}
                ></div>
              </Row>
              <div
                className="d-flex flex-column flex-md-row gap-4 mx-4"
                style={{
                  justifyContent: "space-evenly",
                  marginTop: "7rem",
                  flexWrap: "",
                }}
              >
                {/* Service 1 */}
                <div className="text-center mb-4">
                  <img
                    src="https://eon3group.com/wp-content/uploads/2022/01/market-analysis-300x300.png"
                    alt="International Business Development"
                    className="img-fluid"
                    width="100"
                  />
                  <div className="text-muted text-center">
                    <h5
                      className="fw-lighter w-50 mt-3"
                      style={{ fontFamily: "Gideon Roman", margin: "auto" }}
                    >
                      International Business Development
                    </h5>
                  </div>
                </div>

                <div
                  className="d-none d-xl-block"
                  style={{
                    borderLeft: "1px solid #c9d1ce",
                    height: "100px",
                    margin: "20px 15px",
                  }}
                />

                {/* Service 2 */}
                <div className="text-center mb-4">
                  <img
                    src="https://eon3group.com/wp-content/uploads/2022/01/bank-300x300.png"
                    alt="Real Estate Business"
                    className="img-fluid"
                    width="100"
                  />
                  <div className="text-muted">
                    <h5
                      className="fw-lighter w-50 mt-3"
                      style={{ fontFamily: "Gideon Roman", margin: "auto" }}
                    >
                      Real Estate Business
                    </h5>
                  </div>
                </div>

                <div
                  className="d-none d-xl-block"
                  style={{
                    borderLeft: "1px solid #c9d1ce",
                    height: "100px",
                    margin: " 15px",
                  }}
                />

                {/* Service 3 */}
                <div className="text-center">
                  <img
                    src="https://eon3group.com/wp-content/uploads/2022/01/promotion-300x300.png"
                    alt="Strategic Communications"
                    className="img-fluid"
                    width="100"
                  />
                  <div className="text-muted">
                    <h5
                      className="fw-lighter mt-3 w-50"
                      style={{ fontFamily: "Gideon Roman", margin: "auto" }}
                    >
                      Strategic Communications
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <Container style={{ marginTop: "5rem" }}>
          <hr />
        </Container>
        <Container>
          <h2 className="text-center py-5">Header Text Goes Here</h2>
        </Container>
        <Container>
          <hr />
        </Container>

        {/* Part 4 */}
        <div style={{ backgroundColor: "white", height: "auto" }}>
          <Container className="px-3">
            <div
              style={{
                backgroundColor: "#e3bd29",
                height: "45svh",
                borderRadius: "10px",
              }}
              className="mt-5 c"
            >
              <h3 className="text-center mt-5" style={{ color: "black" }}>
                video
              </h3>
            </div>

            <h2 className=" text-center" style={{ marginTop: "15rem" }}>
              Header Text Goes Here
            </h2>

            <Row className="d-flex mt-5" style={{ justifyContent: "center" }}>
              <Col
                xl={3}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "30vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  video
                </h3>
              </Col>
              <Col
                xl={3}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "30vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  video
                </h3>
              </Col>
              <Col
                xl={3}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "30vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  video
                </h3>
              </Col>
              <Col
                xl={3}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "30vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  video
                </h3>
              </Col>
              <Col
                xl={3}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "30vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  video
                </h3>
              </Col>
              <Col
                xl={3}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "30vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  video
                </h3>
              </Col>
              <Col
                xl={3}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "30vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  video
                </h3>
              </Col>
              <Col
                xl={3}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "30vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  video
                </h3>
              </Col>
              <Col
                xl={3}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "30vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  video
                </h3>
              </Col>

              <div className="text-center mt-5">
                <Button
                  style={{ borderRadius: "50px", backgroundColor: "" }}
                  className="p-3 btn btn-dark"
                >
                  View more
                </Button>
              </div>
            </Row>
          </Container>
        </div>

        <Container className="" style={{ marginTop: "20rem" }}>
          <h2 className="text-center mt-5">Header Text Goes Here</h2>
        </Container>
        <Container>
          <hr />
        </Container>
        <Container>
          <h4 className="text-center">Sub - Header Goes Here</h4>
        </Container>
        <Container>
          <h2 className="text-center">Header Goes Here</h2>
        </Container>
        <Container>
          <h6 className="text-center">Small Header Goes Here</h6>
        </Container>

        {/* Part 5 */}
        <div style={{ backgroundColor: "white", height: "auto" }}>
          <Container className="px-3">
            <Row className="d-flex mt-5" style={{ justifyContent: "center" }}>
              <Col
                xl={5}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "45vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  image
                </h3>
              </Col>
              <Col
                xl={5}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "45vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  image
                </h3>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="" style={{ marginTop: "20rem" }}>
          <h4 className="text-center">Sub - Header Goes Here</h4>
        </Container>
        <Container>
          <h2 className="text-center">Header Goes Here</h2>
        </Container>
        <Container>
          <h6 className="text-center">Small Header Goes Here</h6>
        </Container>
        <Container>
          <hr />
        </Container>

        {/* Part 6 */}
        <div style={{ backgroundColor: "white", height: "auto" }}>
          <Container className="px-3">
            <Row className="d-flex mt-5" style={{ justifyContent: "center" }}>
              <Col
                xl={5}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "45vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  image
                </h3>
              </Col>
              <Col
                xl={5}
                style={{
                  backgroundColor: "#e3bd29",
                  height: "45vh",
                  borderRadius: "10px",
                }}
                className="m-1"
              >
                <h3 className="text-center mt-5" style={{ color: "black" }}>
                  image
                </h3>
              </Col>
            </Row>

            <div className="text-center mt-5">
              <Button
                style={{ borderRadius: "50px", backgroundColor: "" }}
                className="p-3 btn btn-dark"
              >
                View more
              </Button>
            </div>
          </Container>
        </div>

        {/* Part 7 */}
        <div
          className="bg-img img-fluid"
          style={{
            height: "40vh",
            marginTop: "20rem",
            backgroundColor: "rgba(227, 189, 41, 0.7)",
          }}
        >
          <Container className="p-5">
            <div className="d-flex justify-content-between align-items-center mt-5">
              <div>
                <Container>
                  <h2 className="text-start text-light">
                    Investment Opportunities
                  </h2>
                </Container>
                <Container>
                  <h6 className="text-start text-light">
                    Small Header Goes Here
                  </h6>
                </Container>
                <Container>
                  <hr />
                </Container>
              </div>

              <div>
                <Button
                  style={{ borderRadius: "50px", backgroundColor: "" }}
                  className="p-3 btn btn-light"
                >
                  View more
                </Button>
              </div>
            </div>
          </Container>
        </div>

        {/* Part 8 */}
        <div
          style={{
            height: "40vh",
            marginTop: "10rem",
          }}
        >
          <Container>
            <Sli />
          </Container>
        </div>

        <Container style={{ marginTop: "10rem" }}>
          <h2 className="text-start">Our Partners</h2>
        </Container>

        {/* Part 9 */}
        <div
          style={{
            height: "auto",
          }}
        >
          <Container>
            <Row className="justify-content-center mt-5">
              {[...Array(10)].map((_, index) => (
                <Col key={index} xl={2} md={3} sm={6} xs={3} className="m-1">
                  <img
                    src="https://eon3group.com/wp-content/uploads/2023/04/Fidelity.jpg"
                    className="img-fluid rounded"
                    alt={`Image ${index + 1}`}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        {/* Part 10*/}
        <div
          style={{
            height: "auto",
            marginTop: "10rem",
          }}
        >
          <Container>
            <NewsSlider />
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
