import { createSlice } from "@reduxjs/toolkit";

const storedWishlist =
  localStorage.getItem("wishlist") !== null
    ? JSON.parse(localStorage.getItem("wishlist"))
    : [];

const initialState = {
  wishlist: storedWishlist,
};

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToWishlist: (state, action) => {
      const productToAdd = action.payload.product;
      if (!state.wishlist.some((item) => item.id === productToAdd.id)) {
        state.wishlist.push({ ...productToAdd });
      }
    },
    removeFromWishlist: (state, action) => {
      const productToRemove = action.payload;
      state.wishlist = state.wishlist.filter(
        (item) => item.id !== productToRemove.id
      );
    },
  },
});

export const wishlistMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type?.startsWith("wishlist/")) {
    const wishlist = store.getState().wishlist.wishlist;
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }
  return result;
};

export const {
  addToWishlist,
  removeFromWishlist,
} = wishlistSlice.actions;

export default wishlistSlice.reducer;
