import React from 'react';
import ContentLoader from 'react-content-loader';
import LazyLoad from 'react-lazyload';
import { Container } from 'reactstrap';

const SkeletonLoader = () => (
  <ContentLoader
    speed={2}
    width={1200}
    height={300}
    viewBox="0 0 1200 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    {/* Title */}
    <rect x="0" y="0" rx="5" ry="5" width="300" height="30" />

    {/* Services */}
    {[0, 1, 2, 3].map((item, index) => (
      <React.Fragment key={index}>
        {/* Service Image */}
        <rect x={index * 300} y="50" rx="5" ry="5" width="100" height="100" />

        {/* Service Text */}
        <rect x={index * 300} y="160" rx="5" ry="5" width="100" height="20" />
      </React.Fragment>
    ))}
  </ContentLoader>
);

const ServiceCard = ({ imageUrl, altText, serviceName, fontFamily }) => (
  <div className="text-center mb-4">
    <LazyLoad height={200} offset={100}>
      {/* Lazy-loaded content */}
      <img
        src={imageUrl}
        alt={altText}
        className="img-fluid"
        width="100"
      />
      <div className="text-center mt-4 text-muted">
        <h5 className="fw-lighter" style={{ fontFamily }}>
          {serviceName}
        </h5>
      </div>
    </LazyLoad>
  </div>
);

const YourComponent = () => {
  return (
    <div style={{ backgroundColor: 'white', height: 'auto' }} className="mt-5">
      <Container className="p-5" style={{ marginTop: '10rem' }}>
        <div data-aos="fade-right">
          <h2 className="text-muted">Explore our other services</h2>
        </div>

        <div
          className="d-flex flex-wrap justify-content-around align-items-center"
          style={{ gap: '30px', marginTop: '8rem' }}
        >
          {/* Service 1 */}
          <ServiceCard
            imageUrl="https://eon3group.com/wp-content/uploads/2022/01/market-analysis-300x300.png"
            altText="International Business Development"
            serviceName="International Business Development"
            fontFamily="GideonRoman"
          />

          {/* Service 2 */}
          <ServiceCard
            imageUrl="https://eon3group.com/wp-content/uploads/2022/01/bank-300x300.png"
            altText="Real Estate Business"
            serviceName="Real Estate Business"
            fontFamily="Gideon Roman"
          />

          {/* Service 3 */}
          <ServiceCard
            imageUrl="https://eon3group.com/wp-content/uploads/2022/01/promotion-300x300.png"
            altText="Strategic Communications"
            serviceName="Strategic Communications"
            fontFamily="Gideon Roman"
          />

          {/* Service 4 */}
          <ServiceCard
            imageUrl="https://eon3group.com/wp-content/uploads/2022/02/hands-300x300.png"
            altText="Government Relations"
            serviceName="Government Relations"
            fontFamily="Gideon Roman"
          />
        </div>
      </Container>

      {/* bg img */}
      <div
        style={{ width: 'auto', overflow: 'hidden', height: '40svh' }}
        className="svg-bg"
      ></div>
    </div>
  );
};

export default YourComponent;
